<template>
  <div class="list row">
    <h2>Cesije</h2>
    <div class="actions d-flex flex-row-reverse">
      <button
        type="button"
        class="btn btn-primary bi bi-arrow-clockwise"
        @click="refreshData"
      >
        Osveži tabelu
      </button>
      <button type="button" class="btn btn-success bi bi-plus" @click="addData">
        Dodaj novu
      </button>
      <!-- <button
        type="button"
        class="btn btn-secondary bi bi-download"
        @click="downloadCsv"
      />
      <button
        type="button"
        class="btn btn-secondary bi bi-printer"
        @click="printData"
      /> -->
    </div>
    <data-table
      url="cession"
      :columns="columns"
      :templates="templates"
      :headings="headers"
      :actions="actions"
      :extra_actions="extra_actions"
      ref="datatable"
      @yes="deleteData"
      name="Cessions"
      :modalTitle="title"
    >
    </data-table>
  </div>
</template>

<script>
import CessionsDataService from "../../services/cessions.service";
import CessionInvoiceDataService from "../../services/cessions-invoice.service";
import DataTable from "../widgets/DataTable.vue";
import ConfirmDialog from "../widgets/ConfirmDialog.vue";
import PrintDialog from "../widgets/PrintDialog.vue";
import con from "../../constants/constants";
import ListDialog from "../widgets/ListDialog.vue";
import utils from "../../utils/common";
import Files from "../files/Files.vue";

export default {
  name: "Cessions",
  components: {
    DataTable,
  },
  data() {
    return {
      columns: [
        "cession_date",
        "id",
        "Dealer.name",
        "Leasing.name",
        "bb_neto_eur",
        "brand",
        "model",
        "contract_nr",
        "registration_nr",
        "edit",
        "extra_actions",
      ],
      headers: {
        cession_date: "Datum",
        id: "RB.",
        "Dealer.name": "Diler",
        "Leasing.name": "Lizing kuca",
        bb_neto_eur: "BB neto (EUR)",
        brand: "Marka",
        model: "Model",
        contract_nr: "Broj ugovora",
        registration_nr: "Registracija",
        edit: "",
        extra_actions: "Upload | KR/R",
      },
      templates: {
        bb_neto_eur(h, row) {
          return Number.parseFloat(row.bb_neto_eur).toLocaleString("sr-RS", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        },
      },
      actions: {
        btn1: {
          color: "primary",
          icon: "bi-pencil",
          action: (data) => {
            this.$router.push({
              path: `/cessions/${data.id}`,
              // params: { invoice: { data } },
            });
          },
        },
        delete: {
          color: "danger",
          icon: "bi-trash",
          action: (data) => this.deleteOrStorno(data),
        },
        print: {
          color: "success",
          icon: "bi-printer",
          action: (data) => this.printDialog(data),
        },
      },
      extra_actions: {
        btn1: {
          count: "files",
          color: "warning",
          icon: "bi-download",
          action: (data) => this.addFile(data),
        },
        btn2: {
          countIcon: "Normal",
          color: "indigo",
          icon: "bi-journal-plus",
          // pref:"R",
          action: (data) => this.addDoc("Normal", data),
        },
      },
      currentTutorial: null,
      currentIndex: -1,
      title: "id",
    };
  },
  computed: {
    taxRates() {
      return this.$store.state.app.taxRates;
    },
  },
  methods: {
    async refreshData() {
      this.$refs.datatable.onRefresh();
    },
    addData() {
      this.$router.push({
        path: `/cessions/0`,
        params: { data: {} },
      });
    },
    deleteDialog(id) {
      this.$store.commit("app/showModal", {
        modal: ConfirmDialog,
        params: {
          message: "Da li ste sigurni?",
          title: "Obrisi cesiju: " + id,
          onYes: () => this.confirmYes(id),
          onNo: this.confirmNo,
          // foother: { isComponent: true, commponent: test },
        },
      });
    },
    confirmYes(id) {
      this.deleteData(id);
    },
    confirmNo() {
      this.$store.commit("app/hideModal");
    },
    stornoDialog(data) {
      this.$store.commit("app/showModal", {
        modal: ConfirmDialog,
        params: {
          message: "Da li ste sigurni da zelite da stornirate racun?",
          title: "Storniranje racuna: " + data.id,
          onYes: () => this.confirmStorno(data),
          onNo: this.confirmNo,
          // foother: { isComponent: true, commponent: test },
        },
      });
    },
    confirmStorno(data) {
      console.log({ data });
      data["invoices"]["st_doc_nr"] =
        "S" + data["invoices"]["doc_nr"];
      CessionInvoiceDataService.update(
        data["invoices"]["id"],
        data["invoices"]
      )
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Podaci su promenjeni!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    deleteData(id) {
      CessionsDataService.delete(id)
        .then((response) => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: response.data.message,
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    deleteOrStorno(data) {
      if (data["invoices"] && data["invoices"].length > 0) {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: "Postoje povezani racuni. Brisanje nije dozvoljeno!",
          });
      } else {
        this.deleteDialog(data.id);
      }
    },
    printDialog(data) {
      // const invoice = data["invoices"][data["invoices"].length - 1];
      // console.log({invoice});

      if (data.id) {
        const reports = [{ url: `/report/${data.id}/ces/o`, label: "Odluka" }];
        for (const invoice of data["invoices"]) {
        if (data["invoices"] && invoice["doc_nr"]) {
          reports.push(
            {
              url: `/report/${data.id}/ces/pr/${invoice["id"]}`,
              label: "Predracun broj: P" + invoice["doc_nr"],
            },
            {
              url: `/report/${data.id}/ces/spr/${invoice["id"]}`,
              label: "Specifikacija računa broj: " + invoice["doc_nr"],
            },
           
          );
        }
        //  if (data["invoices"] && invoice["doc_nr"] && !invoice["storno_date"]) {
        //   reports.push({
        //       url: `/report/${data.id}/ces/r`,
        //       label: "Racun broj: " + invoice["doc_nr"],
        //     });
        // }
        // if (data["invoices"] && invoice["storno_date"]) {
        //   reports.push({
        //     url: `/report/${data.id}/ces/sr`,
        //     label: "Storno Racun broj: " + invoice["doc_nr"],
        //   });
        // }
        }
        reports.push({
          url: `/report/${data.id}/ces/u`,
          label: "Ugovor o cesiji",
        });

        this.$store.commit("app/showModal", {
          modal: PrintDialog,
          params: {
            title: "Stampa dokumenata za cesiju: " + data.id,
            message: "Izaberi tip stampe.",
            reports,
          },
        });
      }
    },
    addDoc(type, data) {
      if (data.invoices.filter((x) => x.invoice_type == type).length > 0) {
        const items = data.invoices.map((invoice) => {
          return invoice.invoice_type == type
            ? {
                label: `Status: <b>${
                  con.statuses[invoice.invoice_status].label
                }</b> Tip: <b>${con.type[invoice.invoice_type].label}</b> ${
                  invoice.doc_nr ? `Broj: <b>${invoice.doc_nr}</b>` : ""
                } Iznos BRUTO RSD: <b>${
                  invoice.invoice_items.length > 0
                    ? invoice.invoice_items.reduce((accumulator, object) => {
                        return Number.parseFloat(
                          Number.parseFloat(accumulator) +
                            object.price * object.quantity +
                            Number.parseFloat(object.tax)
                        ).toFixed(2);
                      }, 0)
                    : 0
                }</b> Uplaceno RSD: <b>${
                  invoice.invoice_payments.length > 0
                    ? invoice.invoice_payments.reduce((accumulator, object) => {
                        return Number.parseFloat(
                          Number.parseFloat(accumulator) +
                            Number.parseFloat(object.payment)
                        ).toFixed(2);
                      }, 0)
                    : 0
                } </b> `,
                url: `/invoice/${type}/${invoice.id}`,
                btnText: "Otvori",
                target: "blank",
                url2: `/invoice/print/${type}/${invoice.id}/1`,
                btnText2: "Print",
                target2: "blank",
                url3: `/invoice/print/${type}/${invoice.id}/0`,
                btnText3: "Print bez valute",
                target3: "blank",
              }
            : null;
        });
        const itemsByType = items.filter((item) => item != null);
        // console.log(data);
        this.$store.commit("app/showModal", {
          modal: ListDialog,
          params: {
            title: "Cesija: " + data.id,
            message: `Povezani ${utils
              .typeName(type, 2)
              .toLowerCase()} za cesiju ${data.id}.`,
            items: itemsByType,
            actions:
              {
                    btn: {
                      color: "primary",
                      icon: "bi-journal-plus",
                      label: `Kreiraj ${utils.typeName(type, 1).toLowerCase()}`,
                      action: () => this.createDoc(type, data),
                    },
                  },
          },
        });
      } else {
        this.createDoc(type, data);
      }
    },
    async createDoc(type, data) {
    const tax = this.taxRates.find((t) => t.id == data.article.tax);
    CessionsDataService.createDocument({
        id: data.id,
        type: type,
        exchange: data.exchange,
        exchange_date: data.exchange_date,
        exchange_value: data.exchange_value,
        customers_id: data.Dealer.id,
        comment: `Ugovor lesing kuće: ${data.Leasing.name}`,
        items: [{
          quantity: 1,
          price: data.bb_neto_rsd,
          price_exc: data.bb_neto_eur,
          tax: data.bb_neto_rsd * (Number.parseFloat(tax.rate) / 100),
          tax_exc: data.bb_neto_eur * (Number.parseFloat(tax.rate) / 100),
          article_id: data.article.id,
        }],
        
        })
        .then((res) => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Dokument je kreiran!",
          });
          utils.confirmNo();
          this.$router.push({
            path: `/invoice/${type}/${res.data.data.id}`,
          });
          // window.open(routeData.href, '_blank');
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
  },
  addFile(data) {
      console.log({ data });
      this.$store.commit("app/showModal", {
        modal: Files,
        params: {
          pid: data.id,
          parent: "cession",
          title: "Lista dokumenata",
        },
      });
    },
  },
};
</script>

<style>
.Cessions_table_row > td:nth-child(5){
  text-align: right;
  font-weight: bold;
}

.Cessions_table_row > td{
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Cessions_table_row > td:nth-child(3),
.Cessions_table_row > td:nth-child(4) {
  min-width: 250px;
  max-width: 250px;
  font-size: small;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
